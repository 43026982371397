<template>
  <v-row class="fill-height lighten-4">
    <left-side-image/>
    <v-col cols="12" md="7">


      <v-container justify="center">

        <v-row cols="12" justify="center" class="pr-4 mt-0 align-center">

          <v-col sm="9" md="9">
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>
                  <h1 class="mb-0 wrap-text today-light">{{ company.name }}</h1>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <h3 class="mb-0 today-regular line-height-1-2">Usuario: {{ user.name }}</h3>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col sm="3" md="3">
            <v-menu
                v-model="showMenu"
                bottom
                offset-y
                style="max-width: 600px"
                rounded="lg"
            >

              <template v-slot:activator="{ on, attrs }">
                <v-avatar v-bind="attrs"
                          v-on="on"
                          class="ml-5 float-right" size="60"
                          style="background-color: white; color: #555555; border: 1px solid;">
                  <v-img
                      :src="company.image"
                  ></v-img>
                </v-avatar>
              </template>

              <v-list>
                <v-list-item
                    :key="0"
                    link
                    @click="logout"
                >
                  <v-list-item-title>Cerrar sesión</v-list-item-title>
                </v-list-item>
              </v-list>

            </v-menu>


          </v-col>
        </v-row>


        <v-tabs
            color="primary"
            class="mb-12"
            background-color="transparent"
        >
          <v-tab class="px-8">Generar QR</v-tab>
          <v-tab class="px-8">Historial</v-tab>

          <v-tab-item
              :key="1"
          >
            <v-container fluid class="">

              <v-row justify="center" class="mb-1">
                <v-col cols="10" md="8" lg="11">

                  <div class="mt-10 pb-6">
                    <v-form
                        ref="form"
                        lazy-validation
                        @submit.prevent="createProduct">

                      <v-row>
                        <v-col
                            cols="12"
                            md="6"
                        >
                          <v-text-field
                              type="text"
                              v-model="productName"
                              :rules="[rules.required, rules.counterMaxName]"
                              label="Nombre del producto *"
                              required
                              outlined
                              dense
                              color="primary"
                              class="input-field"
                              @input="validate($event, 0)"
                          ></v-text-field>

                        </v-col>

                        <v-col
                            cols="12"
                            md="6"
                        >

                          <currency-input
                              ref="productValue"
                              :options="{
                                locale: 'en-US',
                                currency: 'USD',
                                currencyDisplay: 'symbol',
                                hideCurrencySymbolOnFocus: false,
                                hideGroupingSeparatorOnFocus: false,
                                hideNegligibleDecimalDigitsOnFocus: false,
                                autoDecimalDigits: false,
                                autoSign: false,
                                useGrouping: true,
                                accountingSign: true
                              }"
                              :label="'Valor a financiar'"
                              v-on:input="validate($event, 1)"
                          />

                        </v-col>
                      </v-row>

                      <v-textarea
                          counter
                          outlined
                          name="description"
                          label="Descripción del producto *"
                          :rules="[rules.required, rules.counterMin, rules.counterMaxDescription]"
                          v-model="description"
                          @input="validate($event, 0)"
                      ></v-textarea>

                      <v-layout justify-center pt-6>
                        <v-card-actions>
                          <v-btn
                              :loading="loading"
                              :disabled="btnDisable"
                              color="primary"
                              class="px-14 vardi-button"
                              type="submit"
                              elevation="0"
                              :rounded="true">
                            Crear QR
                          </v-btn>
                        </v-card-actions>
                      </v-layout>

                    </v-form>
                  </div>

                </v-col>
              </v-row>

            </v-container>
          </v-tab-item>

          <v-tab-item
              :key="2"
          >
            <v-container fluid>

              <v-row class="mt-5 mb-5">
                <v-col cols="12" md="6">
                  <v-card color="grey lighten-4" elevation="0">

                    <v-row justify="center" class="px-4 text-center">

                      <v-col justify-center cols="10" md="10" class="my-auto">
                        <h3 class="mb-0 today-regular" style=" line-height: 1;">TOTAL CRÉDITOS APROBADOS</h3>
                      </v-col>
                      <v-col justify-center cols="10" md="10" class="my-auto py-0 mb-2">
                        <h2 class="primary--text today-regular py-0" style=" line-height: 1;">{{ dataProducts.credits_qty }}</h2>
                      </v-col>
                    </v-row>

                  </v-card>
                </v-col>
                <v-col cols="12" md="6">
                  <v-card color="grey lighten-4" elevation="0">

                    <v-row justify="center" class="px-4 text-center">

                      <v-col justify-center cols="10" md="10" class="my-auto">
                        <h3 class="mb-0 today-regular" style=" line-height: 1;">TOTAL MONTO APROBADO</h3>
                      </v-col>
                      <v-col justify-center cols="10" md="10" class="my-auto py-0 mb-2">
                        <h2 class="primary--text today-regular py-0" style=" line-height: 1;">${{ formatCurrency(dataProducts.credits_value) }}</h2>
                      </v-col>
                    </v-row>

                  </v-card>
                </v-col>
              </v-row>


              <v-simple-table dense
                              fixed-header
              >
                <template v-slot:default class="table-striped">
                  <thead>
                  <tr class="header-table">
                    <th class="text-center today-regular black--text" style="background-color: rgba(0, 0, 0, .05);">
                      Producto
                    </th>
                    <th class="text-center today-regular black--text" style="background-color: rgba(0, 0, 0, .05);">
                      Valor
                    </th>
                    <th class="text-center today-regular black--text" style="background-color: rgba(0, 0, 0, .05);">
                      Estado
                    </th>
                    <th class="text-center today-regular black--text" style="background-color: rgba(0, 0, 0, .05);">
                      Fecha
                    </th>
                    <th class="text-center today-regular black--text" style="background-color: rgba(0, 0, 0, .05);">
                      Cliente
                    </th>
                    <th class="text-center today-regular black--text" style="background-color: rgba(0, 0, 0, .05);">
                      Cédula
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="item in dataProducts.credits"
                      v-if="item.credit_data.user != null"
                      :key="item.name" >
                    <td>{{ item.product_name }}</td>
                    <td>${{ formatCurrency(item.product_price) }}</td>
                    <td>{{ item.credit_data.credit_status_description.name }}</td>
                    <td>{{ item.date }}</td>
                    <td>{{ item.credit_data.user.name }}</td>
                    <td>{{ item.credit_data.user.document_id }}</td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>


            </v-container>
          </v-tab-item>
        </v-tabs>

        <div class="mt-15 text-center grey--text" >
          Powered by <a href="https://bio.credit" target="_blank">BioCredit Colombia S.A.S.</a>Todos los derechos reservados 2023
        </div>

        <v-dialog
            v-model="showModalQr"
            max-width="500"
            overlay-color="black"
        >
          <v-card class="pa-4 text-center">

            <v-row align="center" justify="center" class="mt-2">
              <v-col align="center" justify="center" cols="12" md="12" lg="9">
                <h2 class="my-4 today-medium justify-center ">Código QR de referencia de pago</h2>

                <h3 class="text-center today-light line-height-1 px-4">El código tiene un tiempo de vigencia de 2 horas, despues de este tiempo se tendrá que generar una nueva referencia de pago.</h3>

              </v-col>

              <v-col align="center" justify="center" cols="12" md="12" lg="8">
                <v-img
                    v-if="storedProduct"
                    :src="storedProduct.qr"
                    max-width="70%"
                ></v-img>
              </v-col>

              <v-col align="center" justify="center" cols="12" md="12" lg="9">

                <h3 class="text-center primary--text today-light line-height-1 px-4">Solicitale a tu cliente que escanee el código QR con la aplicación de BioCredit</h3>

              </v-col>
            </v-row>

            <v-card-actions class="mt-4 mb-2 justify-center">

              <v-btn
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  class="px-14 vardi-button"
                  type="submit"
                  elevation="0"
                  :rounded="true"
                  @click="downloadImage(storedProduct.qr)">
                Descargar
              </v-btn>

            </v-card-actions>
          </v-card>
        </v-dialog>


      </v-container>


    </v-col>
  </v-row>


</template>

<script>

import LeftSideImage from '@/components/Layouts/LeftSideImage'
import {locals} from "@/helpers/locals";
import {constants} from "@/helpers/constants";
import {api} from "@/helpers/axios";
import axios from 'axios';
import CurrencyInput from '@/components/CurrencyInput'



export default {
  name: 'Product',
  components: {
    LeftSideImage, CurrencyInput
  },
  data() {
    return {
      tab: null,
      description: '',
      productName: '',
      productValue: '',
      showMenu: false,
      dataProducts: [],
      rules: {
        required: value => !!value || 'Ingresa la información',
        minValue: value => value >= 300000 || 'El valor debe ser de al menos $300.000',
        maxValue: value => value <= 3000000 || 'El valor no debe ser mayor de $3.000.000',
        counterMaxName: value => value.length <= 50 || 'Cantidad máxima 50 caracteres',
        counterMaxDescription: value => value.length <= 200 || 'Cantidad máxima 200 caracteres',
        counterMax: value => value.length <= 10 || 'Cantidad máxima 10 caracteres',
        counterMin: value => value.length >= 20 || 'Cantidad minima 20 caracteres'
      },
      loading: false,
      btnDisable: true,
      user: null,
      company: null,
      storedProduct: null,
      showModalQr: false
    }
  },
  computed:{
  },
  created() {
    this.company = locals.getLocal('company');
    this.user = locals.getLocal('user');
    this.loadProducts();
  },
  methods: {
    validate (event, isProduct) {
      let  valid  = this.$refs.form.validate()
      if(isProduct) {
        this.productValue = event
        console.log(this.$refs['productValue'], this.productValue)
        if(this.productValue <= 300000) {
          this.$refs['productValue'].$refs.inputRef.error = true;
          this.$refs['productValue'].$refs.inputRef.errorMessages = ['El valor debe ser de al menos $300.000'];
        } else if(this.productValue >= 3000000) {
          this.$refs['productValue'].$refs.inputRef.error = true;
          this.$refs['productValue'].$refs.inputRef.errorMessages = ['El valor no debe ser mayor de $3.000.000'];
        } else {
          valid = true;
          this.$refs['productValue'].$refs.inputRef.error = false;
          this.$refs['productValue'].$refs.inputRef.errorMessages = [];
        }
      }
      this.btnDisable = !valid
    },
    loadProducts() {

      api.get(constants.endPoints.listProducts, true)
          .then(response => {
            console.log('products:', response);
            this.dataProducts = response.data;
          }).catch(function (error) {
        //
        console.log('errors: ', error);

        try {
          let status = error.response.status;
          if(status == 401){
            locals.clearLocal();
            this.$router.push('/');
          }
          let errors = error.response.data.errors;
          let message = '';
          for (var [key, value] of Object.entries(errors)) {
            console.log(key + ' : ' + value);
            message = message + value + '<br>';
          }
          this.snackbarMessage = message;
          this.snackbar = true;
          this.loading = false;
        } catch (e) {
          console.log(e);
          locals.clearLocal();
          this.$router.push('/');
        }

      }.bind(this))

    },
    createProduct() {
      this.productName
      this.loading = true;
      var config = {
        headers: {'Content-Type': 'multipart/form-data'}
      };

      api.post(constants.endPoints.storeProducts, {
        credit_line_id: 3,
        description: this.description,
        name: this.productName,
        price: this.productValue,
        'product_images[]': '@/assets/logo-horizontal.png'
      }, true).then(function (response) {

        console.log('storeProducts', response);

        this.description = '';
        this.productName = '';
        this.productValue = 0;

        this.storedProduct = response.data;

        if(this.storedProduct.status) {
          this.showModalQr = true;
        }
        this.loading = false;


      }.bind(this)).catch(function (error) {
        let errors = error.response.data;
        console.log('errors: ', error, error.response);
        let message = '';
        for (var [key, value] of Object.entries(errors)) {
          console.log(key + ' : ' + value);
          message = message + value + '<br>';
        }
        this.snackbarMessage = message;
        this.snackbar = true;
        this.loading = false;

      }.bind(this))

    },

    async downloadImage(url) {
      const respuesta = await fetch(url);
      const blob = await respuesta.blob();
      const urlImagen = URL.createObjectURL(blob);
      const enlaceDescarga = document.createElement('a');
      enlaceDescarga.href = urlImagen;
      enlaceDescarga.download = this.productName + '.jpg';
      enlaceDescarga.click();
      URL.revokeObjectURL(urlImagen);
    },

    logout() {
      locals.clearLocal();
      this.$router.push('/');
    },

    formatCurrency: function(value) {
      var formatter = new Intl.NumberFormat('en-ES', {
        style: 'decimal',
        minimumFractionDigits: 0,
      });
      return formatter.format(parseInt(value, 10));

    },
  }
}
</script>

<style>
.theme--light.v-tabs-items {
  background-color: transparent;
  box-shadow: 0px 6px 15px grey;
  border-radius: 10px;
}

.v-tab--active {
  color: inherit;
  background-color: #ff9800;
  color: white !important;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  background-color: #eeeeee;
}

.v-messages__wrapper {
  float: right;
}

#container-copy {
  position: initial;
}
#copyright {
  position: absolute;
  bottom: 0;
  right: 22%;
  color: gray;
}

.header-table {
  height: 50px;
}
</style>

